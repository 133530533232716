import { GFPP, GFPP_IDS } from './manifest-commons'
import translations from '../../../utils/translations'

export const createMultiStepManifest = () => ({
  showFocusBox: true,
  displayName: 'stateBox',
  behavior: { duplicatable: false },
  gfpp: {
    desktop: {
      mainAction1: {
        actionId: GFPP_IDS.NEW_FORM_SETTINGS,
        label: translations.t('formSettings.gfppTitle'),
      },
      mainAction2: {
        actionId: GFPP_IDS.ADD_FIELD,
        label: translations.t('addField.gfppTitle'),
      },
      iconButtons: {
        //settings: GFPP.REMOVE,
        design: GFPP.REMOVE,
      },
    },
    mobile: {
      /* use default configurations */
    },
  },
})
